exports.components = {
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontakty-js": () => import("./../../../src/pages/kontakty.js" /* webpackChunkName: "component---src-pages-kontakty-js" */),
  "component---src-pages-muzeum-a-media-js": () => import("./../../../src/pages/muzeum-a-media.js" /* webpackChunkName: "component---src-pages-muzeum-a-media-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-projekt-na-doniocz-ukoncen-js": () => import("./../../../src/pages/projekt-na-doniocz-ukoncen.js" /* webpackChunkName: "component---src-pages-projekt-na-doniocz-ukoncen-js" */),
  "component---src-pages-sbirka-donio-js": () => import("./../../../src/pages/sbirka-donio.js" /* webpackChunkName: "component---src-pages-sbirka-donio-js" */)
}

